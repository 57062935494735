import React, { useEffect, useState } from 'react'
import { handleAuthentication, logout } from '../utils/auth'
import { Alert, Pane } from 'evergreen-ui'

const Callback = () => {
  const [authError, setAuthError] = useState('')

  const handleLogout = () => {
    logout()
  }

  useEffect(() => {
    handleAuthentication().catch(err => setAuthError(err.error))
  }, [])

  if (authError) {
    return (
      <Pane
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Alert
          intent="danger"
          title="We're sorry, something went wrong.'"
          onClick={handleLogout}
        >
          <p>{authError && authError.toString()}</p>
          <p>
            Please click anywhere on this message and you will be taken to the
            login screen. Click Forgot Password.
          </p>
        </Alert>
      </Pane>
    )
  }

  return null
}

export default Callback
